import GameManager from "./GameManager"
import Button from "./UI/Button"
import DPad from "./UI/DPad"
import Gradient from "./UI/Gradient"

class UIManager { 
    constructor (gameManager)
    {
        this.#gameManager = gameManager
        this.#gameManager.addEventListener(GameManager.EVENT_MOUSE_DOWN,'UIManager_MouseDown',this.#handleMouseDown)
        this.#gameManager.addEventListener(GameManager.EVENT_MOUSE_MOVE,'UIManager_MouseMove',this.#handleMouseMove)
        this.#gameManager.addEventListener(GameManager.EVENT_MOUSE_UP,'UIManager_MouseUp',this.#handleMouseUp)
        this.#gameManager.addEventListener(GameManager.EVENT_TOUCH_START,'UIManager_TouchStart',this.#handleTouchStart)
        this.#gameManager.addEventListener(GameManager.EVENT_TOUCH_MOVE,'UIManager_TouchMove',this.#handleTouchMove)
        this.#gameManager.addEventListener(GameManager.EVENT_TOUCH_END,'UIManager_TouchEnd',this.#handleTouchEnd)
        this.#gameManager.addToDrawList('UIManager',this.#drawElements)
    }

    addButton(name,symbol,x,y,size,func,options) {
        if (this.#elements[name]) return null
        this.#elements[name] = new Button(this.#gameManager.getCanvas(),name,symbol,x,y,size,func,options)
        return this.#elements[name]
    }

    addGradient(name,x,y,width,height,colors,angle) {
        if (this.#elements[name]) return null
        this.#elements[name] = new Gradient(this.#gameManager.getCanvas(),x,y,width,height,colors,angle)
        return this.#elements[name]
    }

    addDPad(name,x,y,size,oversize,options) {
        if (this.#elements[name]) return null
        this.#elements[name] = new DPad(this.#gameManager.getCanvas(),x,y,size,oversize,options)
        return this.#elements[name]
    }

    removeElement(name) {
        delete this.#elements[name]
    }

    getElement(name) {
        return this.#elements[name]
    }

    #handleMouseDown = (e) => {
        for (let element in this.#elements) {
            if (this.#elements[element].type === 'DPad') {
                const dpad = this.#elements[element]
                dpad.bufferInput(this.#gameManager.x,this.#gameManager.y)
            }
            if (this.#elements[element].type === 'Button') {
                const button = this.#elements[element]
                if (button.detect(this.#gameManager.x,this.#gameManager.y)) {
                    button.runFunction()
                }
            } 
        }
    }

    #handleMouseMove = (e) => {
        for (let element in this.#elements) {
            if (this.#gameManager.pressing) {
                if (this.#elements[element].type === 'DPad') {
                    const dpad = this.#elements[element]
                    dpad.bufferInput(this.#gameManager.x,this.#gameManager.y)
                }
            }
        }
    }

    #handleMouseUp = (e) => {
    }

    #handleTouchStart = (e) => {
        for (let element in this.#elements) {
            if (this.#elements[element].type === 'DPad') {
                const dpad = this.#elements[element]
                dpad.bufferInput(this.#gameManager.x,this.#gameManager.y)
            }
        }
    }

    #handleTouchMove = (e) => {
        for (let element in this.#elements) {
            if (this.#elements[element].type === 'DPad') {
                const dpad = this.#elements[element]
                dpad.bufferInput(this.#gameManager.x,this.#gameManager.y)
            }
        }
    }

    #handleTouchEnd = (e) => {
        for (let element in this.#elements) {
            if (this.#elements[element].type === 'Button') {
                const button = this.#elements[element]
                if (button.detect(this.#gameManager.x,this.#gameManager.y)) {
                    button.runFunction()
                    e.preventDefault()
                }
            }
        }
    }

    #drawElements = () => {
        document.body.style.cursor = 'auto'
        for (let id in this.#elements) {
            if (this.#elements[id].draw(this.#gameManager.x,this.#gameManager.y,this.#gameManager.pressing)) {
                document.body.style.cursor = 'pointer'
            }
        }
    }

    /** @type {GameManager} */
    #gameManager

    #elements = {}
}

export default UIManager