import UIManager from '../../util/UIManager'
import GameManager from '../../util/GameManager'
import { Database } from '../../../Firebase'
import {
  query,
  collection,
  orderBy,
  getDocs,
  limit
} from '@firebase/firestore'


/**
 
 * @param i input reference 
 */
export default function addHighscoreScene(m,u) {
    manager = m
    ui = u
    m.addScene('highscore',draw,()=>{},state,select,unselect)
    GetHighscores()
}
/** @type {GameManager} */
let manager = null

/** @type {UIManager} */
let ui = null

async function GetHighscores() {
    const snakeColRef = collection(Database,'SnakeHighscores')
    const snakeQuery = query(snakeColRef, orderBy('score','desc'), limit(10))
    const snakeQuerySnapshot = await getDocs(snakeQuery)

    state.highscores = []
    snakeQuerySnapshot.forEach((doc) => {
        state.highscores.push(doc.data())
    })
}
  
const draw = () => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'white'
    ctx.textAlign = "center"
    ctx.font = 'bold 72px Poppins';
    ctx.fillText('HIGHSCORES',canvas.width/2,100)

    let index = 0
    for (let hs of state.highscores) {
        const name = hs.name
        const score = hs.score

        ctx.font = 'bold 42px Poppins';
        ctx.fillText(name.toUpperCase(),canvas.width/2-100,190 + 65*index)
        ctx.fillText(score,canvas.width/2+100,190 + 65*index)

        index++
    }
}

const state = {}

const select = () => {
    ui.addButton('backToMainMenu','MAIN MENU',500,900,60,()=>{
        manager.selectScene('menu')
    },{outlineWidth:6,padding:[10,100,100,0]})
    GetHighscores()
}

const unselect = () => {
    ui.removeElement('backToMainMenu')
}

