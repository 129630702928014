import { 
    getDatabase, 
    onValue,
    onDisconnect,
    update,
    get,
    ref
} from "firebase/database";
import GameManager from "../../../util/GameManager";
import {getRowCol} from '../play'
import {draw,state,place,myUpdate,updateGame} from './shared'

const db = getDatabase();

export default function addGuestScene(m,u) {
    manager = m
    ui = u
    m.addScene('guest',myDraw,myUpdate,state,select,unselect)
}

let manager = null
let ui = null

const myDraw = () => {
    draw(manager)

    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    if (state.game.turn === 2) {
        ctx.textAlign = "right"
        ctx.font = 'bold 32px Poppins';
        ctx.fillStyle = 'white'
        ctx.fillText('Your Turn...',950,950)
    } else {
        ctx.textAlign = "right"
        ctx.font = 'bold 32px Poppins';
        ctx.fillStyle = 'white'
        ctx.fillText('Opponents Turn...',950,950)
    }
}

let unsub1 = null, unsub2 = null
let dcRef1 = null, dcRef2 = null
const select = (last,options) => {
    state.gameRef = options.gameRef
    state.waitRef = options.waitRef
    const connectedRef = ref(db, '.info/connected');
    unsub1 = onValue(connectedRef, async (snap) => {
        if (snap.val() === true) {
            const waitingRef = state.waitRef
            const gameRef = state.gameRef
            dcRef1 = onDisconnect(waitingRef)
            dcRef2 = onDisconnect(gameRef)
            dcRef1.update({open:true})
            dcRef2.update({guest:''})
        }
    })

    unsub2 = onValue(state.gameRef, (snap) => {
        if (snap.val()) {
            if (snap.val().clientUpdate) {
                if (state.client.timestamp !== snap.val().clientUpdate.timestamp) {
                    state.client.timestamp = snap.val().clientUpdate.timestamp
                    state.client.winText = snap.val().clientUpdate.winText
                    state.client.winTextTimer = snap.val().clientUpdate.winTextTimer
                }
            }

            state.client.player1Name = snap.val().host.toUpperCase()
            state.client.player2Name = snap.val().guest.toUpperCase()
            const gameState = JSON.parse(snap.val().game)
            for (let prop in gameState) {
                state.game[prop] = gameState[prop]
            }

            console.log(snap.val().guest)
            console.log(manager.getState('menu').username)

            if (snap.val().guest !== manager.getState('menu').username) {
                manager.selectScene('list')
            }

        } else {
            manager.selectScene('list')
        }
    });

    if (GameManager.ISTOUCH) {
        manager.addEventListener(GameManager.EVENT_TOUCH_START,'tttTouchStart',(e) => {
            if (state.game.turn === 2) state.client.hover = getRowCol(manager.x,manager.y)
        })
        manager.addEventListener(GameManager.EVENT_TOUCH_MOVE,'tttTouchMove',() => {
            if (state.game.turn === 2) state.client.hover = getRowCol(manager.x,manager.y)
        })
        manager.addEventListener(GameManager.EVENT_TOUCH_END,'tttTouchEnd',() => {
            if (state.game.turn === 2) {
                state.client.hover = [-1,-1]
                const [x,y] = getRowCol(manager.x,manager.y)
                if (place(x,y)) updateGame()
            }
        })
    } else {
        manager.addEventListener(GameManager.EVENT_MOUSE_DOWN,'tttMouseDown',() => {
            if (state.game.turn === 2) {
                state.client.hover = [-1,-1]
                const [x,y] = getRowCol(manager.x,manager.y)
                if (place(x,y)) updateGame()
            }
        })
        manager.addEventListener(GameManager.EVENT_MOUSE_MOVE,'tttMouseMove',()=> {
            if (state.game.turn === 2) state.client.hover = getRowCol(manager.x,manager.y)
        })
    }

    ui.addButton('tttGameBack','←',80,940,100,()=>{
        manager.selectScene('list')
    },{outlineWidth:0,padding:[-10,0,0,-30]})
}

const unselect = async () => {
    dcRef1.cancel()
    dcRef2.cancel()
    unsub1()
    unsub2()
    if (GameManager.ISTOUCH) {
        manager.removeEventListener(GameManager.EVENT_TOUCH_START,'tttTouchStart')
        manager.removeEventListener(GameManager.EVENT_TOUCH_MOVE,'tttTouchMove')
        manager.removeEventListener(GameManager.EVENT_TOUCH_END,'tttTouchEnd')
    } else {
        manager.removeEventListener(GameManager.EVENT_MOUSE_DOWN,'tttMouseDown')
        manager.removeEventListener(GameManager.EVENT_MOUSE_MOVE,'tttMouseMove')
    }

    const playingRef = state.gameRef
    const playing = await get(playingRef)
    const playingVal = playing.val()

    if (playingVal && playingVal.guest === manager.getState('menu').username) {
        const waitingRef = ref(db, playingVal.wait)
        update(waitingRef, {open: true})
        update(playingRef, {guest: ''})
    }
  
    ui.removeElement('tttGameBack')
}
