import { useEffect, useRef } from "react";

function Unselected() {
    const canvasRef = useRef(null)
    const canvasStyle = {
        'backgroundColor': 'black'
    }

    useEffect(() => {
        /** @type {HTMLCanvasElement} */
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.clearRect(0,0,canvas.width,canvas.height)
        context.fillStyle = '#ffffff'
        context.font = "bold 48px Poppins";
        context.textAlign = "center"
        context.fillText("Select a game!",canvas.width/2,canvas.height/2)
      }, [])

    return (
        <canvas style={canvasStyle} ref={canvasRef} width="1000" height="1000"/>
    )
}

export default Unselected;
