import { Outlet } from "react-router-dom";
import Menu from "./Menu/Menu";

import logo from "../../assets/logo.svg"
import title from "../../assets/title.svg"
import './Header.css'

function Header() {
    return (
        <>
            <div className='HeaderBar'>
                <a href="/" className='BrandContainer'>
                    <img src={logo} alt='logo' className='Logo'/>
                    <img src={title} alt='title' className='Title'/>
                </a>
                <div className='Navigation'>
                    <div className='Menu'>
                        <Menu/>                 
                    </div>
                    <div className='Links'>
                        <a href='/projects'>Projects</a>
                        <a href='/games'>Games</a>
                        <a href='/about'>About</a>
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Header;