import GameManager from "../../util/GameManager"
import { Database } from '../../../Firebase'
import {
    collection,
    addDoc,
} from '@firebase/firestore'


/** @type {DPad} */
let dpad = null

/** @type {UIManager} */
let ui = null

/** @type {GameManager} */
let manager = null

/**
 * @param {GameManager} m 
 * @param i input reference 
 */
export default function addPlayScene(m,u) {
    ui = u
    manager = m
    m.addScene('play',draw,update,state,select,unselect)
}

export const state = {} 

export const draw = () => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    const dimension = state.dimension
    const snake = state.snake
    const fruit = state.fruit

    let [row,col] = fruit.split(',')
    ctx.fillStyle = 'rgb(255, 10, 10)'
    ctx.fillRect(1000/dimension*row+100/dimension,1000/dimension*col+100/dimension,1000/dimension-200/dimension,1000/dimension-200/dimension)

    let color = [251,176,64]
    let length = snake.length
    for (let pos of snake) {
        [row,col] = pos.split(',')

        ctx.fillStyle = `rgb(${color[0]},${color[1]},${color[2]})`
        ctx.fillRect(1000/dimension*row-1,1000/dimension*col-1,1000/dimension+2,1000/dimension+2)
        
        color[0] -= 251 / length
        color[1] -= 1 / length
        color[2] += 175 / length
    }

    ctx.fillStyle = 'white'
    ctx.textAlign = "center"
    ctx.font = 'bold 48px Poppins';
    ctx.fillText(state.score.toString(),canvas.width/2,100)
}

const update = (delta) => {
    const updateAfterTicks = 100/state.gameSpeed

    state.gameTick += delta / 16
    if (state.gameTick > updateAfterTicks) {
        state.gameTick = 0

        const horizontal = ['left','right']
        const vertical = ['up','down']

        
        const istouch = GameManager.ISTOUCH

        let nextInput = istouch? dpad.getNextInput() : state.inputBuffer.shift()
        while(nextInput != null) {
            let updateInput = false
            if (nextInput === 'left' && !horizontal.includes(state.direction)) updateInput = true
            else if (nextInput === 'right' && !horizontal.includes(state.direction)) updateInput = true
            else if (nextInput === 'up' && !vertical.includes(state.direction)) updateInput = true
            else if (nextInput === 'down' && !vertical.includes(state.direction)) updateInput = true
                
            if (updateInput) {
                state.direction = nextInput
                nextInput = null
            } else {
                nextInput = istouch? dpad.getNextInput() : state.inputBuffer.shift()
            }
        }

        switch (state.direction) {
            case 'left': 
                move(-1,0);
                break;
            case 'right': 
                move(1,0);
                break;
            case 'down': 
                move(0,1);
                break;
            case 'up': 
                move(0,-1);  
                break;
            default:
                break; 
        }
    }
}

const select = (from) => {
    if (from === 'menu') startNewGame()
    dpad = ui.getElement('HandHeldDPad')
    if (GameManager.ISTOUCH) while(dpad.getNextInput());

    manager.addEventListener(GameManager.EVENT_KEY_DOWN,'arrowinput',(e)=>{
        if (['ArrowRight','ArrowLeft','ArrowDown','ArrowUp'].includes(e.code)) {
            const state = manager.getState('play')
            e.preventDefault()

            let input
            switch (e.code) {
            case 'ArrowRight':
                input = 'right'
                break;
            case 'ArrowLeft':
                input = 'left'
                break;
            case 'ArrowUp':
                input = 'up'
                break; 
            case 'ArrowDown':
                input = 'down'
                break;  
            default:
                input = 'none'
                break
            }

            if (input !== 'none') {
                if (!state.inputBuffer.includes(input)) {
                    state.inputBuffer.push(input)
                }
            }
        }
    })
}

const unselect = () => {
    manager.removeEventListener(GameManager.EVENT_KEY_DOWN,'arrowinput')
}

function startNewGame() {
    const highscoreState = manager.getState('highscore')
    const length = highscoreState.highscores.length
    const lowest = length === 0? {score:0} : highscoreState.highscores[length-1]
    if (state.score > lowest.score) {
        const menuState = manager.getState('menu')
        const username = menuState.username
        addHighScore(username,state.score)
    }

    state.score = 0
    state.grow = 3
    state.gameTick = 0
    state.gameSpeed = 8
    state.dimension = 15
    state.fruit = getRandomLocation() 
    state.direction = 'none'
    state.snake = [getRandomLocation()]
    state.inputBuffer = []
}

function getRandomLocation() {
    const cantBe = []
    if (state.snake) {
        for (let pos of state.snake) {
            cantBe.push(pos)
        }
    }
    if (state.fruit) cantBe.push(state.fruit)

    let newPos = null
    while (newPos == null || cantBe.includes(newPos)) {
        const newX = Math.random() * state.dimension
        const newY = Math.random() * state.dimension
        newPos = `${Math.floor(newX)},${Math.floor(newY)}`
    }

    return newPos
}

function move(x,y) {
    const head = state.snake[0]
    const headPos = head.split(',')
    const [oX,oY] = headPos
    let [newX,newY] = [Number(oX)+x,Number(oY)+y]
    const newHead = `${newX},${newY}`
    if (newHead === state.fruit) {
        state.score += 1
        if (state.score % 10 === 0 && state.dimension < 100) state.dimension += 5
        if (state.gameSpeed < 20) state.gameSpeed += 0.2
        state.grow += 3
        state.fruit = getRandomLocation()
    }

    state.snake.unshift(newHead)
    if (state.grow > 0) state.grow -= 1
    else state.snake.pop()

    if(checkCollision(newX,newY)) startNewGame()
}

function checkCollision(x,y) {
    const bodySet = new Set(state.snake);
    if (bodySet.size !== state.snake.length) return true 
    if (x < 0 || y < 0) return true
    if (x >= state.dimension || y >= state.dimension) return true

    return false
}

async function addHighScore(name,score) {
    const snakeColRef = collection(Database,'SnakeHighscores')
    await addDoc(snakeColRef, {
        name: name,
        score: score
    });
}