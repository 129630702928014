import UIManager from '../../../util/UIManager'
import GameManager from '../../../util/GameManager'
import { 
    getDatabase, 
    ref,  
    onValue, 
    push, 
    set,
    update,
    get
} from "firebase/database";
const db = getDatabase();


export default function addListScene(m,u) {
    manager = m
    ui = u
    m.addScene('list',draw,myUpdate,state,select,unselect)
}

/** @type {GameManager} */
let manager = null

/** @type {UIManager} */
let ui = null

const draw = () => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'rgb(50,50,50)'
    ctx.fillRect(0,150,1000,700)

    const length = state.gameList.length
    for (let i = 0; i < length; i++ ) {
        const game = state.gameList[i]
        const host = game.host.toUpperCase()

        const height = 125*i - state.scrollHeight

        ctx.beginPath()
        ctx.roundRect(50,200 + height,900,100,10)
        ctx.strokeStyle = 'white'
        ctx.fillStyle = 'black'
        ctx.lineWidth = 6
        ctx.stroke()
        ctx.fill()
        ctx.closePath()

        ctx.textAlign = "left"
        ctx.fillStyle = 'white'
        ctx.font = 'bold 48px Poppins';
        ctx.fillText(host,100,263 + height)
    }

    ctx.fillStyle = 'black'
    ctx.fillRect(0,0,1000,180)
    ctx.fillRect(0,820,1000,180)

    ctx.fillStyle = 'white'
    ctx.textAlign = "center"
    ctx.font = 'bold 72px Poppins';
    ctx.fillText('MULTIPLAYER',canvas.width/2,112)
}



const myUpdate = () => {
    state.buttons.forEach((button)=> {
        ui.removeElement(button.name)
    })

    state.buttons = []
    const length = state.gameList.length
    for (let i = 0; i < length; i++ ) {
        const game = state.gameList[i]
        const height = 255 + 125*i - state.scrollHeight
        if (height >= 255 && height <= 800) {
            state.buttons.push(ui.addButton(game.game,'JOIN',840,height,50,() => {
                joinGame(game.game)
            },{outlineWidth:0,hoverOutline:[50,230,50,1]}))
        }
    }

    state.maxScrollHeight = -625 + 125*length
    if (state.maxScrollHeight < 0) state.maxScrollHeight = 0
}

async function joinGame(gameID) {
    const playingRef = ref(db, gameID)
    const playing = await get(playingRef)
    const playingVal = playing.val()
    
    const waitingRef = ref(db, playingVal.wait)
    await update(waitingRef, {open: false})

    const username = manager.getState('menu').username
    await update(playingRef, {guest: username})

    manager.selectScene('guest',{gameRef: playingRef,waitRef: waitingRef})
}

//This has to change!! otherwise games may be created over and over again!
async function createGame() {
    const waitingRef = ref(db, 'TTT/waiting');
    const playingRef = ref(db, 'TTT/playing');
    const connectedRef = ref(db, '.info/connected');

    const playing = await push(playingRef);
    const waiting = await push(waitingRef);

    const username = manager.getState('menu').username
    const waitingPath = waiting._path.pieces_.join('/')
    const playingPath = playing._path.pieces_.join('/')
    set(waiting, {
        open: true,
        host: username,
        game: playingPath
    });
    set(playing, {
        host: username,
        guest: '',
        game: '{"started":1,"turn":1,"player1":0,"player2":0,"grid":[[0,0,0],[0,0,0],[0,0,0]]}',
        wait: waitingPath
    });

    manager.selectScene('host',{gameRef: playing,waitRef: waiting})
}

const state = {
    maxScrollHeight: 0,
    scrollHeight: 0,
    gameList: [],
    buttons: [],
    lastY:0
}

let unsub = () => {}
const select = () => {
    const waitingRef = ref(db, 'TTT/waiting')
    onValue(waitingRef, (snap) => {
        state.gameList = []
        snap.forEach(child => {
            if (child.val().open) {
                state.gameList.push(child.val())
            }
        }) 
    })

    ui.addButton('tttBack','←',90,920,100,()=>{
        manager.selectScene('menu')
    },{outlineWidth:0,padding:[-10,0,0,-30]})

    ui.addButton('tttCreate','CREATE GAME',700,920,60,()=>{
        createGame()
    },{padding:[12,50,50,0]})


    if (GameManager.ISTOUCH) {
        manager.addEventListener(GameManager.EVENT_TOUCH_START,'tttTouch', (e) => {
            state.lastY = e.pageY
        })

        manager.addEventListener(GameManager.EVENT_TOUCH_MOVE,'tttSwipe',(e) => {
            const delta = state.lastY - e.pageY

            if (Math.abs(delta) >= 30) {
                state.lastY = e.pageY
                if (delta > 0 ) state.scrollHeight += 125
                if (delta < 0 ) state.scrollHeight -= 125
            }

            if (state.scrollHeight < 0) state.scrollHeight =  0
            if (state.scrollHeight > state.maxScrollHeight) state.scrollHeight = state.maxScrollHeight
        })
    } else {
        manager.addEventListener(GameManager.EVENT_SCROLL,'tttScroll',(e) => {
            if (e.wheelDelta > 0 ) state.scrollHeight -= 125
            if (e.wheelDelta < 0 ) state.scrollHeight += 125
            
            if (state.scrollHeight < 0) state.scrollHeight =  0
            if (state.scrollHeight > state.maxScrollHeight) state.scrollHeight = state.maxScrollHeight
        })
    }
}
    

const unselect = () => {
    unsub()
    ui.removeElement('tttBack')
    state.buttons.forEach((button)=> {
        ui.removeElement(button.name)
    })
    ui.removeElement('tttCreate')

    if (GameManager.ISTOUCH) {
        manager.removeEventListener(GameManager.EVENT_TOUCH_START,'tttTouch')
        manager.removeEventListener(GameManager.EVENT_TOUCH_MOVE,'tttSwipe')
    } else {
        manager.removeEventListener(GameManager.EVENT_SCROLL,'tttScroll')
    }
    
}