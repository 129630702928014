import {useDocumentTitle} from "../../hooks/setDocumentTitle"
import './About.css'

function About() {
    useDocumentTitle("About");

    return (
        <div className='About'>
            <span className='Title'>Who Am I?</span>
            <span className='Description'>
                My name is Jeremiah Lynn. I am a third year student at Washington State 
                University pursuing a degree in Computer Science. 
                <br/><br/>
                My passion in computer science is software development. I love creating 
                tools and experiences to simplify and better others’ lives. I have 
                firsthand experience developing yearbook software for Jostens, 
                which provides me the opportunity to collaborate with industry developers, 
                share and gain different perspectives, and create user-friendly, 
                sustainable solutions for the future.
                <br/><br/>
                As a development entrepreneur, I led a team that created a game using 
                Minecraft as a platform and hosted the game for others to play. This 
                involved developing the entire codebase, coordinating events, working 
                on textures and models, creating an ecosystem of tools for non-technical 
                builders to work more effectively, and recruiting new players.

                <br/><br/>
                I am looking to gain knowledge within the software development field 
                through experience and networking. At this time, I am open to discussing 
                employment opportunities. I look forward to connecting with you on LinkedIn 
                or at my personal email jeremiah9020@gmail.com
            </span>
        </div>
    )
}

export default About;