/**
 * @param {GameManager} manager 
 */
export default function addOpeningScene(m) {
    manager = m
    m.addScene('opening',draw,update,state,select)
}

/** @type {GameManager} */
let manager = null

export const state = {
    animationFrame: 0,
    animationTick: 0,
    word: [['S',1,10],['N',1,11],['A',1,12],['K',1,13],['E',1,14]],
    speed: 2
}

export const draw = () => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    const color = [251,176,64]
    const length = state.word.length
    for (let letter of state.word) {
        const x = letter[1]
        const y = letter[2]
        ctx.fillStyle = `rgb(${color[0]},${color[1]},${color[2]})`
        ctx.fillRect(1000/9*x,1000/9*y,1000/9,1000/9)


        ctx.textAlign = "center"
        ctx.fillStyle = 'black'
        ctx.font = "bold 72px Poppins";
        ctx.fillText(letter[0],1000/9*x+55,1000/9*y+85)
        color[0] -= 251 / length
        color[1] -= 1 / length
        color[2] += 175 / length
    }
}

const update = (delta) => {
    state.animationTick += delta/16
    if (state.animationFrame <= 25 && state.animationTick >= state.speed ) {
        state.speed += 0.33
        state.animationTick = 0
        state.animationFrame += 1

        if (state.animationFrame <= 24)
        for (let letter of state.word) {
            const x = letter[1]
            const y = letter[2]

            if (y === 4 && x > 3) letter[1]--
            else if (x === 1 && y > 1) letter[2]--
            else if ( [1,2].includes(x) && y === 1) letter[1]++
            else if (x === 3 && y < 6) letter[2]++
            else if ( [3,4,5].includes(x) && y === 6) letter[1]++
            else if (x === 6 && y > 4) letter[2]--
        }

        if (state.animationFrame === 25) {
            state.word = [['S',2,4],['N',3,4],['A',4,4],['K',5,4],['E',6,4]]
        }    
    } else if (state.animationFrame > 25 && state.animationFrame <= 30 && state.animationTick >= 5) {
        state.animationTick = 0
        state.animationFrame++
    } else if (state.animationFrame > 30 && state.animationFrame <= 100 && state.animationTick >= 2) {
        state.animationTick = 0
        const frame = state.animationFrame
        if (frame > 30 && frame <= 45) state.word[0][2] += (35 - frame)/20
        if (frame > 40 && frame <= 55) state.word[1][2] += (45 - frame)/20
        if (frame > 50 && frame <= 65) state.word[2][2] += (55 - frame)/20
        if (frame > 60 && frame <= 75) state.word[3][2] += (65 - frame)/20
        if (frame > 70 && frame <= 85) state.word[4][2] += (75 - frame)/20

        state.animationFrame++
    }

    if (state.animationFrame > 100) manager.selectScene('menu')
}

const select = () => {
    state.animationFrame = 0
    state.animationTick = 0
    state.word = [['S',1,10],['N',1,11],['A',1,12],['K',1,13],['E',1,14]]
    state.speed = 2
}