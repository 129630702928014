import './GameSelector.css'

function GameSelector(props) {
    return (
        <button className='GameSelector' onClick={() => props.select(props.name)}>
            <span>
                {props.name}
            </span>
        </button>
    )
}

export default GameSelector;