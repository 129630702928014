import {state, draw} from './play'

/**
 * @param {GameManager} m 
 */
export default function addPauseScene(m,u) {
    manager = m
    ui = u
    m.addScene('pause',pauseDraw,update,state,select,unselect)
}

let ui = null
let manager = null

const pauseDraw = () => {
    draw()

    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.fillStyle='rgba(0,0,0,0.5)'
    ctx.fillRect(0,0,1000,1000)

    ctx.fillStyle = 'white'
    ctx.textAlign = "center"
    ctx.font = 'bold 72px Poppins';
    ctx.fillText('GAME PAUSED',canvas.width/2,200)
}

const update = () => {

}

const select = () => {
    ui.addButton('backToMainMenu','MAIN MENU',500,800,60,()=>{
        manager.selectScene('menu')
    },{outlineWidth:6,padding:[10,100,100,0]})
}

const unselect = () => {
    ui.removeElement('backToMainMenu')
}