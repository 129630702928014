
const badWords = ['fuck','shit','damn','hell','cunt','nigger','chink','cum','pussy','vagina','penis','dick','cock','coon','jap','cush','redskin','bitch']
const badWordTrie = {}

function buildBadWordTrie() {
    for (let word of badWords) {
        let obj = badWordTrie
        for (let i = 0; i < word.length; i++) {
            const letter = word[i]

            if (obj[letter]) obj = obj[letter]
            else {
                if (i === word.length - 1) {
                    obj[letter] = {end: true}
                } else {
                    obj[letter] = {}
                    obj = obj[letter]
                }
            }
        }
    }  
}
buildBadWordTrie()

function isBadWord(name) {
    for (let i = 0; i < name.length; i++) {
        let obj = badWordTrie
        for (let p = i; p < name.length; p++) {
            let letter = name[p]
            if (obj.hasOwnProperty(letter)) {
                obj = obj[letter]
                if (obj.hasOwnProperty('end')) return true
            } else {
                return false
            }
        }
    }
    return false
}

export default isBadWord