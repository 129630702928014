import { 
    update
} from "firebase/database";

export const state = {
    gameRef: null,
    waitRef: null,
    client: {
        timestamp: 0,
        player1Name: '',
        player2Name: '',
        winTextTimer: 0,
        winText: '',
        hover: [-1,-1],
    },
    game: {
        started: 1,
        turn: 1,
        player1: 0,
        player2: 0,
        grid: [[0,0,0],[0,0,0],[0,0,0]]
    }
}

const blue = '#00AEEF'
const yellow = '#FBB040'

export function draw(manager) {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.textAlign = "center"
    ctx.font = 'bold 48px Poppins';

    ctx.fillStyle = 'white'
    if (state.client.winTextTimer > 0 )ctx.fillText(state.client.winText,500,140)

    if (state.client.player2Name !== '') {
        ctx.fillStyle = blue
        ctx.fillText(state.client.player2Name,850,100)
        ctx.fillText(state.game.player2.toString(),850,175)
    } else {
        ctx.fillStyle = 'white'
        ctx.fillText('Waiting',850,125)
    }

    if (state.client.player1Name !== '') {
        ctx.fillStyle = yellow
        ctx.fillText(state.client.player1Name,150,100)
        ctx.fillText(state.game.player1.toString(),150,175)
    } else {
        ctx.fillStyle = 'white'
        ctx.fillText('Waiting',150,125)
    }


    ctx.strokeStyle = 'white'
    ctx.lineWidth = 6
    ctx.beginPath()
    ctx.moveTo(400,300)
    ctx.lineTo(400,900)
    ctx.moveTo(600,300)
    ctx.lineTo(600,900)
    ctx.moveTo(200,500)
    ctx.lineTo(800,500)
    ctx.moveTo(200,700)
    ctx.lineTo(800,700)
    ctx.stroke()
    ctx.closePath()

    for (let row = 0; row < state.game.grid.length; row++) {
        for (let col = 0; col < state.game.grid[row].length; col++) {
            if (state.game.grid[row][col] === 1) {
                drawO(ctx,row,col)
            } else if (state.game.grid[row][col] === 2) {
                drawX(ctx,row,col)
            } else if (state.game.turn === 1 && state.client.hover[0] === row && state.client.hover[1] === col) {
                drawO(ctx,row,col)
            } else if (state.game.turn === 2 && state.client.hover[0] === row && state.client.hover[1] === col) {
                drawX(ctx,row,col)
            }
        }
    }
}

function drawO(ctx,row,col) {
    ctx.strokeStyle = yellow
    ctx.lineWidth = 6
    ctx.beginPath()
    ctx.arc(300+row*200,400+col*200,65,0,360)
    ctx.stroke()
    ctx.closePath()
}

function drawX(ctx,row,col) {
    ctx.strokeStyle = blue
    ctx.lineWidth = 7
    ctx.beginPath()
    ctx.moveTo(235+row*200,335+col*200)
    ctx.lineTo(365+row*200,465+col*200)
    ctx.moveTo(365+row*200,335+col*200)
    ctx.lineTo(235+row*200,465+col*200)
    ctx.stroke()
    ctx.closePath()
}


export function place(inX,inY) {
    if (inX === -1) return false
    if (state.game.grid[inX][inY] === 0) {
        state.game.grid[inX][inY] = state.game.turn
        state.game.turn = (state.game.turn === 1)? 2 : 1
        checkWin(state)
        return true
    }
    return false
}

export function checkWin() {
    const g = state.game.grid

    let winner = 0
    if (g[0][0] !== 0 && g[0][0] === g[0][1] && g[0][0] === g[0][2]) winner = g[0][0]
    else if (g[1][0] !== 0 && g[1][0] === g[1][1] && g[1][0] === g[1][2]) winner = g[1][0]
    else if (g[2][0] !== 0 && g[2][0] === g[2][1] && g[2][0] === g[2][2]) winner = g[2][0]
    else if (g[0][0] !== 0 && g[0][0] === g[1][0] && g[0][0] === g[2][0]) winner = g[0][0]
    else if (g[0][1] !== 0 && g[0][1] === g[1][1] && g[0][1] === g[2][1]) winner = g[0][1]
    else if (g[0][2] !== 0 && g[0][2] === g[1][2] && g[0][2] === g[2][2]) winner = g[0][2]
    else if (g[0][0] !== 0 && g[0][0] === g[1][1] && g[0][0] === g[2][2]) winner = g[0][0]
    else if (g[2][0] !== 0 && g[2][0] === g[1][1] && g[2][0] === g[0][2]) winner = g[0][2]
    else {
        let full = true
        for(let r of g) {
            for (let c of r) {
                if (c === 0) full = false
            }
        }
        if (full) winner = 3
    }

    if (winner !== 0) {
        if (winner === 1) {
            state.client.winText = 'Player 1 Wins!'
            state.game.player1++
        } else if (winner === 2) {
            state.client.winText = 'Player 2 Wins!'
            state.game.player2++
        } else {
            state.client.winText = 'Cats Game!'
        }

        state.game.started = (state.game.started === 1)? 2 : 1
        state.game.turn = state.game.started
        state.client.winTextTimer = 3
        state.game.grid = [[0,0,0],[0,0,0],[0,0,0]]
    }
}

export const myUpdate = (delta) => {
    const secPassed = delta/1000
    if (state.client.winTextTimer > 0) state.client.winTextTimer -= secPassed
}

export async function updateGame() {
    const gameRef = state.gameRef

    const ts = new Date(Date.now()).toUTCString()
    let updateObj = null

    if (state.client.winTextTimer !==0 ) {
        state.client.timestamp = ts
        updateObj = {
            winTextTimer: state.client.winTextTimer,
            winText: state.client.winText,
            timestamp: ts
        }
    }

    update(gameRef,{game: JSON.stringify(state.game),clientUpdate: updateObj})
}