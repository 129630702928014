import {useDocumentTitle} from "../../hooks/setDocumentTitle"

function NoPage() {
    useDocumentTitle("Page not found");

    return (
        <div>
            NoPage
        </div>
    )
}

export default NoPage;