class DPad {
    /**
     * @param {Number} x 
     * @param {Number} y 
     * @param {Number} size 
     * @param {Number} oversize 
     * @param {Object} options { pressFillGradient; pressOutlineGradient; fill; outline; outlineWidth;}
     */
    constructor(canvas,x,y,size,oversize,options) {
        this.#canvas = canvas
        this.#x = x
        this.#y = y
        this.#size = size
        this.#oversize = oversize || size
        this.#options = options || {}
        this.#inputBuffer = []
    }

    get type () {
        return 'DPad'
    }

    bufferInput(inX,inY) {
        const input = this.detect(inX,inY)
        if (input !== 'none') {
            if (!this.#inputBuffer.includes(input)) {
                this.#inputBuffer.push(input)
            }
        }
    }

    getNextInput() {
        return this.#inputBuffer.shift()
    }

    detect(inX,inY) {
        const left = this.#x - this.#oversize/2
        const right = this.#x + this.#oversize/2
        const top = this.#y - this.#oversize/2
        const bottom = this.#y + this.#oversize/2

        const topRight = [right,top]
        const bottomRight = [right,bottom]
        const bottomLeft = [left,bottom]
        const topLeft = [left,top]
        const center = [this.#x,this.#y]

        const topZone = [topLeft,topRight,center]
        const rightZone = [topRight,bottomRight,center]
        const bottomZone = [bottomLeft,bottomRight,center]
        const leftZone = [bottomLeft,topLeft,center]

        const length = inX.length
        for (let i = 0; i < length; i++) {
            const x = inX[i]
            const y = inY[i]

            if (this.#matches(topZone,x,y)) return 'up'
            if (this.#matches(bottomZone,x,y)) return 'down'
            if (this.#matches(rightZone,x,y)) return 'right'
            if (this.#matches(leftZone,x,y)) return 'left'
        }
        return 'none'
    }

    #matches(zone,x,y) {
        const a = this.#area(zone[0][0],zone[0][1],zone[1][0],zone[1][1],zone[2][0],zone[2][1])

        const a1 = this.#area(x,y,zone[1][0],zone[1][1],zone[2][0],zone[2][1])
        const a2 = this.#area(zone[0][0],zone[0][1],x,y,zone[2][0],zone[2][1])
        const a3 = this.#area(zone[0][0],zone[0][1],zone[1][0],zone[1][1],x,y)

        const aC = a1 + a2 + a3

        if (a > aC) {
            return a - aC < 1
        } else {
            return aC - a < 1
        }
    }

    #area(x1,y1,x2,y2,x3,y3) {
        return Math.abs((x1*(y2-y3) + x2*(y3-y1)+ x3*(y1-y2))/2);
    }

    draw(inX,inY,pressing) {
        const canvas = this.#canvas
        const ctx = canvas.getContext('2d')
        const outerShape = [
            [this.#x - this.#size/6, this.#y - this.#size/2],
            [this.#x + this.#size/6, this.#y - this.#size/2],
            [this.#x + this.#size/6, this.#y - this.#size/6],
            [this.#x + this.#size/2, this.#y - this.#size/6],
            [this.#x + this.#size/2, this.#y + this.#size/6],
            [this.#x + this.#size/6, this.#y + this.#size/6],
            [this.#x + this.#size/6, this.#y + this.#size/2],
            [this.#x - this.#size/6, this.#y + this.#size/2],
            [this.#x - this.#size/6, this.#y + this.#size/6],
            [this.#x - this.#size/2, this.#y + this.#size/6],
            [this.#x - this.#size/2, this.#y - this.#size/6],
            [this.#x - this.#size/6, this.#y - this.#size/6],
            [this.#x - this.#size/6, this.#y - this.#size/2],
        ]

        let fill,outline,detected = false
        detected = this.detect(inX,inY)


        const defaultFill = this.#options.fill || 'rgb(25,25,25)'
        const defaultOutline = this.#options.fill || 'rgb(230,230,230)'
        const fillGradDark = this.#options.pressFillGradient? this.#options.pressFillGradient[0] : 'rgb(0,0,0)'
        const fillGradLight = this.#options.pressFillGradient? this.#options.pressFillGradient[1] : 'rgb(50,50,50)'
        const outlineGradDark = this.#options.pressOutlineGradient? this.#options.pressOutlineGradient[0] : 'rgb(215,215,215)'
        const outlineGradLight = this.#options.pressOutlineGradient? this.#options.pressOutlineGradient[1] : 'rgb(255,255,255)'

        if (detected === 'none' || !pressing) {
            fill = defaultFill
            outline = defaultOutline
        } else if (pressing) {
            if (detected === 'up') {
                fill = ctx.createLinearGradient(this.#x, this.#y - this.#size/2, this.#x,this.#y + this.#size/2)
                fill.addColorStop(0, fillGradDark)
                fill.addColorStop(1, fillGradLight)

                outline = ctx.createLinearGradient(this.#x, this.#y - this.#size/2, this.#x,this.#y + this.#size/2)
                outline.addColorStop(0, outlineGradDark)
                outline.addColorStop(1, outlineGradLight)
            } else if (detected === 'down') {
                fill = ctx.createLinearGradient(this.#x, this.#y - this.#size/2, this.#x,this.#y + this.#size/2)
                fill.addColorStop(1, fillGradDark)
                fill.addColorStop(0, fillGradLight)

                outline = ctx.createLinearGradient(this.#x, this.#y - this.#size/2, this.#x,this.#y + this.#size/2)
                outline.addColorStop(1, outlineGradDark)
                outline.addColorStop(0, outlineGradLight)
            } else if (detected === 'right') {
                fill = ctx.createLinearGradient(this.#x - this.#size/2, this.#y, this.#x + this.#size/2,this.#y)
                fill.addColorStop(1, fillGradDark)
                fill.addColorStop(0, fillGradLight)

                outline = ctx.createLinearGradient(this.#x - this.#size/2, this.#y, this.#x + this.#size/2,this.#y)
                outline.addColorStop(1, outlineGradDark)
                outline.addColorStop(0, outlineGradLight)
            } else if (detected === 'left') {
                fill = ctx.createLinearGradient(this.#x - this.#size/2, this.#y, this.#x + this.#size/2,this.#y)
                fill.addColorStop(0, fillGradDark)
                fill.addColorStop(1, fillGradLight)

                outline = ctx.createLinearGradient(this.#x - this.#size/2, this.#y, this.#x + this.#size/2,this.#y)
                outline.addColorStop(0, outlineGradDark)
                outline.addColorStop(1, outlineGradLight)
            }
        }

        ctx.fillStyle = fill
        ctx.lineWidth = this.#options.outlineWidth || this.#size / 20
        ctx.strokeStyle = outline

        ctx.beginPath()
        ctx.moveTo(outerShape[0][0],outerShape[0][1])
        outerShape.forEach((point) => {
            ctx.lineTo(point[0],point[1])
        })
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
    }

    #canvas
    #x
    #y
    #size
    #oversize
    #options
    #inputBuffer
}

export default DPad