import {useDocumentTitle} from "../../hooks/setDocumentTitle"

function Projects() {
    useDocumentTitle("Projects");

    return (
        <div>
            Projects
        </div>
    )
}

export default Projects;