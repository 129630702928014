import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import Games from "./pages/Games/Games";
import About from "./pages/About/About";
import Connect from "./pages/Connect/Connect";
import NoPage from "./pages/NoPage/NoPage";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className='Content'>
        <Routes>
          <Route index element={<Home />} />
          <Route path="games" element={<Games />} />
          <Route path="projects" element={<Projects />} />
          <Route path="connect" element={<Connect />} />
          <Route path="about" element={<About />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
