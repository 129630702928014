import './GameLibrary.css'

function GameLibrary(props) {
    return (
        <div className='GameLibrary'>
            {props.children}
        </div>
    )
}

export default GameLibrary;