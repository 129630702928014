import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
 
const firebaseConfig = {
    apiKey: "AIzaSyCOGWM7DkcurUTUmaKkRFYwD7qDejARqvc",
    authDomain: "jeremiah-lynn.firebaseapp.com",
    projectId: "jeremiah-lynn",
    storageBucket: "jeremiah-lynn.appspot.com",
    messagingSenderId: "223205651684",
    appId: "1:223205651684:web:a5f322450ddd69acdae8f1",
    measurementId: "G-SW8Y5G515M",
    https: "https://jeremiah-lynn-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const Database = getFirestore(firebaseApp);