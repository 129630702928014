import { useEffect, useRef } from "react"
import GameManager from "../util/GameManager"
import UIManager from "../util/UIManager"

import addOpeningScene from './scenes/opening'
import addMenuScene from './scenes/menu'
import addPlayScene from './scenes/play'
import addPauseScene from "./scenes/pause"
import addHighscoreScene from "./scenes/highscore"

function Snake() {
    const inputRef = useRef(null)
    const canvasRef = useRef(null)
    const istouch = GameManager.ISTOUCH
    const canvasWidth = 1000
    const canvasHeight = (istouch ? window.innerHeight / window.innerWidth * 1000 : 1000)
    const manager = new GameManager()
    const ui = new UIManager(manager)

    addOpeningScene(manager)
    addMenuScene(manager,inputRef,ui)
    addPlayScene(manager,ui)
    addPauseScene(manager,ui)
    addHighscoreScene(manager,ui)

    useEffect(() => {
        manager.init(canvasRef.current)
        if (istouch) {
            ui.addGradient('HandHeldGradient',0,1000,1000,canvasHeight-1000,[[0,'rgb(251, 176, 64)'],[1,'rgb(219, 138, 18)']],200)
            ui.addDPad('HandHeldDPad',500,1300,400)
            ui.addButton('HandHeldPause',' P ',150,1150,100,()=> {
                if (manager.getSelectedScene() === 'play') manager.selectScene('pause')
                else if (manager.getSelectedScene() === 'pause') manager.selectScene('play')
            },{radius:110,outlineWidth:30,padding:[10,10,10,-20]})
        }
        manager.addEventListener(GameManager.EVENT_KEY_DOWN,'ESCAPETOPAUSE',(e)=>{
            if (e.code === 'Escape') {
                if (manager.getSelectedScene() === 'play') manager.selectScene('pause')
                else if (manager.getSelectedScene() === 'pause') manager.selectScene('play')
            }
        })
        manager.selectScene('opening')
        return () => {
            manager.finish()
        }
    })

    const canvasStyle = { 'backgroundColor': 'black' }
    const inputStyle = {
        textTransform: 'uppercase',
        display:'none',
        position:'absolute',
        left:'10%',
        right:'10%',
        top:`${80000/canvasHeight}%`,
        height:`${10000/canvasHeight}%`,
        padding:'0'
    }

    return (
        <>
            <canvas style={canvasStyle} ref={canvasRef} width={canvasWidth} height={canvasHeight}/>
            <input ref={inputRef} type='text' maxLength='8' placeholder='enter name' style={inputStyle}/>
        </>
    )
}

export default Snake