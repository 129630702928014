class Gradient {
    constructor(canvas,x,y,width,height,colors,angle) {
        this.#canvas = canvas
        this.#x = x
        this.#y = y
        this.#width = width
        this.#height = height
        this.#colors = colors
        this.#angle = angle
    }

    get type () {
        return 'Gradient'
    }

    draw() {
        /** @type {CanvasRenderingContext2D}*/
        const ctx = this.#canvas.getContext('2d')
        const cos = Math.cos(Math.PI * this.#angle / 180)
        const sin = Math.sin(Math.PI * this.#angle / 180)

        const x0 = this.#x + this.#width/2 + sin * (this.#width/2)
        const y0 = this.#y + this.#height/2 + cos * (this.#height/2)
        const x1 = this.#x + this.#width/2 - sin * (this.#width/2)
        const y1 = this.#y + this.#height/2 - cos * (this.#height/2)


        const gradient = ctx.createLinearGradient(x0,y0,x1,y1)
        for (let color of this.#colors) {
            gradient.addColorStop(color[0],color[1])
        }

        ctx.fillStyle = gradient;
        ctx.fillRect(this.#x, this.#y, this.#width, this.#height);
        return false
    }

    #canvas
    #x
    #y
    #width
    #height
    #colors
    #angle
}

export default Gradient