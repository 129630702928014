import {draw,state} from './opening'
import isBadWord from '../../util/BadWordChecker'
/**
 * @param {GameManager} m 
 * @param i input reference 
 */
export default function addOpeningMenu(m,i,u) {
    manager = m
    inputRef = i
    ui = u
    m.addScene('menu',draw,update,state,select,unselect)
}

let ui = null
let manager = null
let inputRef = null
let button = null

const update = () => {
    const username = inputRef.current.value.toLowerCase()

    if (isBadWord(username)) {
        state.username = ''
    } else {
        state.username = username
    }

    if (state.username === '') button.disable()
    else button.enable()

    inputRef.current.style.fontSize = `${inputRef.current.offsetWidth/15}px`
}

const select = () => {
    button = ui.addButton('play','PLAY',500,500,60,()=>{
        manager.selectScene('play')
    },{outlineWidth:6,padding:[10,320,320,0]})
    button.disable()

    ui.addButton('hs','HIGHSCORES',500,630,60,()=>{
        manager.selectScene('highscore')
    },{outlineWidth:6,padding:[12,203,202,0]})

    inputRef.current.style.display = 'block' 
}

const unselect = () => {
    ui.removeElement('play')
    ui.removeElement('hs')
    inputRef.current.style.display = 'none' 
}


