class Button {
    /**
     * @param {String} text 
     * @param {Number} x 
     * @param {Number} y 
     * @param {Number} size 
     * @param {Function} func 
     * @param {Object} options { disableFill; disableOutline; hoverFill; hoverOutline; fill; outline; outlineWidth;}
     */
    constructor(canvas,name,text,x,y,size,func,options) {
        this.#canvas = canvas
        this.#name = name
        this.#text = text || ''
        this.#x = x || 0
        this.#y = y || 0
        this.#size = size || 100
        this.#options = options || {}
        this.#func = func
        this.#disabled = false

        const ctx = canvas.getContext('2d')
        ctx.fillStyle = 'white'
        ctx.textAlign = "center"
        ctx.font = `bold ${this.#size}px Poppins`;

        const metrics = ctx.measureText(this.#text)

        this.#padding = options.padding || [0,0,0,0]
        this.#buttonWidth = metrics.width
        this.#buttonHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
    }

    get type () {
        return 'Button'
    }
    get name () {
        return this.#name
    }

    runFunction() {
        this.#func()
    }

    disable() {
        this.#disabled = true
    }

    enable() {
        this.#disabled = false
    }

    detect(inX,inY) {
        if (this.#disabled) return false
        const x1 = this.#x - this.#buttonWidth/2 - this.#padding[1]
        const x2 = this.#x + this.#buttonWidth/2 + this.#padding[2]
        const y1 = this.#y - this.#buttonHeight/2 - this.#padding[0]
        const y2 = this.#y + this.#buttonHeight/2 + this.#padding[3]

        const length = inX.length
        for (let i = 0; i < length; i++) {
            const x = inX[i]
            const y = inY[i]

            if (x >= x1 && x <= x2 && y >= y1 && y <= y2) return true
        }

        return false
    }

    draw(x,y,pressing) {
        let fill,outline,detected = false
        if (this.#disabled) {
            fill = this.#options.disableFill
            ? `rgba(${this.#options.disableFill[0]},${this.#options.disableFill[1]},${this.#options.disableFill[2]},${this.#options.disableFill[3]})` 
            : 'rgba(0,0,0,1)'

            outline = this.#options.disableOutline
            ? `rgba(${this.#options.disableOutline[0]},${this.#options.disableOutline[1]},${this.#options.disableOutline[2]},${this.#options.disableOutline[3]})` 
            : 'rgba(60,60,60,1)'
        } else {
            detected = this.detect(x,y)
            if (detected) {
                fill = this.#options.hoverFill
                ? `rgba(${this.#options.hoverFill[0]},${this.#options.hoverFill[1]},${this.#options.hoverFill[2]},${this.#options.hoverFill[3]})` 
                : 'rgba(0,0,0,1)'

                outline = this.#options.hoverOutline
                ? `rgba(${this.#options.hoverOutline[0]},${this.#options.hoverOutline[1]},${this.#options.hoverOutline[2]},${this.#options.hoverOutline[3]})` 
                : 'rgba(200,200,200,1)'
            } else {
                fill = this.#options.fill
                ? `rgba(${this.#options.fill[0]},${this.#options.fill[1]},${this.#options.fill[2]},${this.#options.fill[3]})` 
                : 'rgba(0,0,0,1)'

                outline = this.#options.outline
                ? `rgba(${this.#options.outline[0]},${this.#options.outline[1]},${this.#options.outline[2]},${this.#options.outline[3]})` 
                : 'rgba(255,255,255,1)'
            }
        }

        const ctx = this.#canvas.getContext('2d')
        const radius = this.#options.radius || 10
        ctx.beginPath()
        ctx.roundRect(
            this.#x - this.#buttonWidth/2 - this.#padding[1],
            this.#y - this.#buttonHeight/2 - this.#padding[0],
            this.#buttonWidth + this.#padding[1] + this.#padding[2],
            this.#buttonHeight + this.#padding[0] + this.#padding[3],
            radius)
        ctx.fillStyle = fill
        ctx.lineWidth = this.#options.outlineWidth || this.#size / 10
        
        ctx.strokeStyle = outline
        if (this.#options.outlineWidth !== 0) {
            ctx.stroke()
        }
        ctx.fill()
        ctx.closePath()

        ctx.textAlign = "center"
        ctx.font = `bold ${this.#size}px Poppins`;
        ctx.fillStyle = outline
        ctx.fillText(this.#text, this.#x,this.#y + this.#size/4.5,)

        return detected
    }

    #canvas
    #name
    #x
    #y
    #size
    #text
    #options
    #func
    #disabled
    #buttonWidth
    #buttonHeight
    #padding
}

export default Button