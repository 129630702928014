import { useEffect } from "react";

const useClickOutside = (ref, func) => {
    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            func();
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref,func]);
};

export {useClickOutside};