import './Footer.css'
import logo_bw from '../../assets/logo_bw.svg'
import instagram from '../../assets/socials/Instagram.png'
import linkedin from '../../assets/socials/LinkedIn.png'
import github from '../../assets/socials/Github.png'

function Footer() {
    return (
        <>
            <div className='Footer'>
                <div className='PageLinks'>
                    <a className='PageLink' href="/">
                        <span className='LinkName'>Home</span>
                    </a>
                    <a className='PageLink' href="/projects">
                        <span className='LinkName'>Projects</span>
                    </a>
                    <a className='PageLink' href="/games">
                        <span className='LinkName'>Games</span>
                    </a>
                    <a className='PageLink' href="/about">
                        <span className='LinkName'>About</span>
                    </a>
                    <a className='PageLink' href="/connect">
                        <span className='LinkName'>Connect</span>
                    </a>
                </div>
                <div className='Branding'>
                    <div className='Socials'>
                        <a className='Social' href="https://www.linkedin.com/in/jeremiah-lynn-2303061b8/">
                            <span className='AccountName'>Jeremiah Lynn</span>
                            <img src={linkedin} alt='LinkedIn'/>
                        </a>
                        <a className='Social' href="https://github.com/jeremiah9020">
                            <span className='AccountName'>jeremiah9020</span>
                            <img src={github} alt='Github'/>
                        </a>
                        <a className='Social' href="https://www.instagram.com/jabberjerry/">
                            <span className='AccountName'>@jabberjerry</span>
                            <img src={instagram} alt='Instagram'/>
                        </a>
                    </div>
                    <img className='Logo' src={logo_bw} alt='logo_bw'/>
                </div>
            </div>
        </>
    )
}

export default Footer;