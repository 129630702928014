import { useEffect, useRef } from "react"
import GameManager from "../util/GameManager"
import UIManager from "../util/UIManager"
import addMenuScene from "./scenes/menu"
import addGuestScene from "./scenes/multiplayer/guest"
import addHostScene from "./scenes/multiplayer/host"
import addListScene from "./scenes/multiplayer/list"
import addPlayScene from "./scenes/play"

function TicTacToe() {
    const inputRef = useRef(null)
    const canvasRef = useRef(null)
    const istouch = GameManager.ISTOUCH
    const canvasWidth = 1000
    const canvasHeight = 1000
    const manager = new GameManager()
    const ui = new UIManager(manager)

    addMenuScene(manager,inputRef,ui)
    addPlayScene(manager,ui)
    addListScene(manager,ui)
    addListScene(manager,ui)
    addHostScene(manager,ui)
    addGuestScene(manager,ui)

    useEffect(()=> {
        manager.init(canvasRef.current)
        manager.selectScene('menu')
        return () => {
            manager.finish()
        }
    })

    const canvasStyle = { 'backgroundColor': 'black' }
    const inputStyle = {
        textTransform: 'uppercase',
        display:'none',
        position:'absolute',
        left:'10%',
        right:'10%',
        top:`${80000/canvasHeight}%`,
        height:`${10000/canvasHeight}%`,
        padding:'0'
    }
    return (
        <>
            <canvas style={canvasStyle} ref={canvasRef} width={canvasWidth} height={canvasHeight}/>
            <input ref={inputRef} type='text' maxLength='8' placeholder='enter name' style={inputStyle}/>
        </>
    )
}

export default TicTacToe