import {useDocumentTitle} from "../../hooks/setDocumentTitle"

function Home() {
    useDocumentTitle("Home");

    return (
        <div>
            Home
        </div>
    )
}

export default Home;