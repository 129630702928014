import GameManager from "../../util/GameManager"
import UIManager from "../../util/UIManager"

/**
 * @param {GameManager} m 
 * @param i input reference 
 */
export default function addPlayScene(m,u) {
    manager = m
    ui = u
    m.addScene('play',()=>{draw(state)},update,state,select,unselect)
}

/** @type {GameManager} */
let manager = null

/** @type {UIManager} */
let ui = null

export const draw = (state) => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.textAlign = "center"
    ctx.font = 'bold 48px Poppins';

    ctx.fillStyle = 'white'
    if (state.winTextTimer > 0 )ctx.fillText(state.winText,500,140)

    ctx.fillStyle = yellow
    ctx.fillText(state.player1Name,150,100)
    ctx.fillText(state.player1.toString(),150,175)

    if (state.player2Name !== '') {
        ctx.fillStyle = blue
        ctx.fillText(state.player2Name,850,100)
        ctx.fillText(state.player2.toString(),850,175)
    } else {
        ctx.fillStyle = 'white'
        ctx.fillText('Waiting',850,125)
    }




    ctx.strokeStyle = 'white'
    ctx.lineWidth = 6
    ctx.beginPath()
    ctx.moveTo(400,300)
    ctx.lineTo(400,900)
    ctx.moveTo(600,300)
    ctx.lineTo(600,900)
    ctx.moveTo(200,500)
    ctx.lineTo(800,500)
    ctx.moveTo(200,700)
    ctx.lineTo(800,700)
    ctx.stroke()
    ctx.closePath()

    for (let row = 0; row < state.grid.length; row++) {
        for (let col = 0; col < state.grid[row].length; col++) {
            if (state.grid[row][col] === 1) {
                drawO(ctx,row,col)
            } else if (state.grid[row][col] === 2) {
                drawX(ctx,row,col)
            } else if (state.turn === 1 && state.hover[0] === row && state.hover[1] === col) {
                drawO(ctx,row,col)
            } else if (state.turn === 2 && state.hover[0] === row && state.hover[1] === col) {
                drawX(ctx,row,col)
            }
        }
    }
}

function drawO(ctx,row,col) {
    ctx.strokeStyle = yellow
    ctx.lineWidth = 6
    ctx.beginPath()
    ctx.arc(300+row*200,400+col*200,65,0,360)
    ctx.stroke()
    ctx.closePath()
}

function drawX(ctx,row,col) {
    ctx.strokeStyle = blue
    ctx.lineWidth = 7
    ctx.beginPath()
    ctx.moveTo(235+row*200,335+col*200)
    ctx.lineTo(365+row*200,465+col*200)
    ctx.moveTo(365+row*200,335+col*200)
    ctx.lineTo(235+row*200,465+col*200)
    ctx.stroke()
    ctx.closePath()
}

const update = (delta) => {
    const secPassed = delta/1000
    if (state.winTextTimer > 0) state.winTextTimer -= secPassed
}

const blue = '#00AEEF'
const yellow = '#FBB040'

const state = {
    player1Name: 'Player 1',
    player2Name: 'Player 2'
}

function setupState() {
    state.winTextTimer = 0
    state.winText = ''
    state.hover = [-1,-1]
    state.started = 1
    state.turn = 1
    state.player1 = 0
    state.player2 = 0
    state.grid = [[0,0,0],[0,0,0],[0,0,0]]
}


const select = () => {
    if (GameManager.ISTOUCH) {
        manager.addEventListener(GameManager.EVENT_TOUCH_START,'tttTouchStart',(e) => {
            state.hover = getRowCol(manager.x,manager.y)
        })
        manager.addEventListener(GameManager.EVENT_TOUCH_MOVE,'tttTouchMove',() => {
            state.hover = getRowCol(manager.x,manager.y)
        })
        manager.addEventListener(GameManager.EVENT_TOUCH_END,'tttTouchEnd',() => {
            state.hover = [-1,-1]
            const [x,y] = getRowCol(manager.x,manager.y)
            place(x,y,state)
        })
    } else {
        manager.addEventListener(GameManager.EVENT_MOUSE_DOWN,'tttMouseDown',() => {
            const [x,y] = getRowCol(manager.x,manager.y)
            place(x,y,state)
        })
        manager.addEventListener(GameManager.EVENT_MOUSE_MOVE,'tttMouseMove',()=> {
            state.hover = getRowCol(manager.x,manager.y)
        })
    }

    ui.addButton('tttBack','←',80,940,100,()=>{
        manager.selectScene('menu')
    },{outlineWidth:0,padding:[-10,0,0,-30]})

    setupState()
}

const unselect = () => {
    if (GameManager.ISTOUCH) {
        manager.removeEventListener(GameManager.EVENT_TOUCH_START,'tttTouchStart')
        manager.removeEventListener(GameManager.EVENT_TOUCH_MOVE,'tttTouchMove')
        manager.removeEventListener(GameManager.EVENT_TOUCH_END,'tttTouchEnd')
    } else {
        manager.removeEventListener(GameManager.EVENT_MOUSE_DOWN,'tttMouseDown')
        manager.removeEventListener(GameManager.EVENT_MOUSE_MOVE,'tttMouseMove')
    }

    ui.removeElement('tttBack')
}

export function getRowCol(inX,inY) {
    let x = -1,y = -1

    if (inX >= 200 && inX < 400) x = 0
    else if (inX >= 400 && inX < 600) x = 1
    else if (inX >= 600 && inX < 800) x = 2
    
    if (inY >= 300 && inY < 500) y = 0
    else if (inY >= 500 && inY < 700) y = 1
    else if (inY >= 700 && inY < 900) y = 2

    if (x === -1 || y === -1) x = y = -1
    return [x,y]
}

export function place(inX,inY,state) {
    if (inX === -1) return false
    if (state.grid[inX][inY] === 0) {
        state.grid[inX][inY] = state.turn
        state.turn = (state.turn === 1)? 2 : 1
        checkWin(state)
        return true
    }
    return false
}

function checkWin(state) {
    const g = state.grid

    let winner = 0
    if (g[0][0] !== 0 && g[0][0] === g[0][1] && g[0][0] === g[0][2]) winner = g[0][0]
    else if (g[1][0] !== 0 && g[1][0] === g[1][1] && g[1][0] === g[1][2]) winner = g[1][0]
    else if (g[2][0] !== 0 && g[2][0] === g[2][1] && g[2][0] === g[2][2]) winner = g[2][0]
    else if (g[0][0] !== 0 && g[0][0] === g[1][0] && g[0][0] === g[2][0]) winner = g[0][0]
    else if (g[0][1] !== 0 && g[0][1] === g[1][1] && g[0][1] === g[2][1]) winner = g[0][1]
    else if (g[0][2] !== 0 && g[0][2] === g[1][2] && g[0][2] === g[2][2]) winner = g[0][2]
    else if (g[0][0] !== 0 && g[0][0] === g[1][1] && g[0][0] === g[2][2]) winner = g[0][0]
    else if (g[2][0] !== 0 && g[2][0] === g[1][1] && g[2][0] === g[0][2]) winner = g[0][2]
    else {
        let full = true
        for(let r of g) {
            for (let c of r) {
                if (c === 0) full = false
            }
        }
        if (full) winner = 3
    }

    if (winner !== 0) {
        if (winner === 1) {
            state.winText = 'Player 1 Wins!'
            state.player1++
        } else if (winner === 2) {
            state.winText = 'Player 2 Wins!'
            state.player2++
        } else {
            state.winText = 'Cats Game!'
        }

        state.started = (state.started === 1)? 2 : 1
        state.turn = state.started
        state.winTextTimer = 3
        state.grid = [[0,0,0],[0,0,0],[0,0,0]]
    }
}