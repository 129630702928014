import './Menu.css'
import {useClickOutside} from "../../../hooks/clickOutsideElement"
import {useResizeWindow} from "../../../hooks/resizeWindow"
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import hamburger from "../../../assets/hamburger.svg"

function Menu() {
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, ()=>{
        setShowMessage(false)
        setShowButton(true)
    });
    useResizeWindow(()=>{
        setShowMessage(false)
        setShowButton(true)
    })

    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    return (
        <>
            {showButton && (
                <button className='Hamburger' onClick={() => setShowMessage(true)}>
                    <img src={hamburger} alt='hamburger'/>
                </button>)
            }
            <CSSTransition
                in={showMessage}
                timeout={600}
                classNames="MenuTransition"
                unmountOnExit
                onEnter={() => setShowButton(false)}
                onExited={() => setShowButton(true)}>
                <div ref={wrapperRef} className='MenuContainer'>
                    <a href='/projects'>Projects</a>
                    <a href='/games'>Games</a>
                    <a href='/about'>About</a>
                </div>
            </CSSTransition>
        </>
    )

}

export default Menu;