import { useLayoutEffect } from 'react';

function useResizeWindow(func) {
    useLayoutEffect(() => {
      function resize() {
        func();
      }
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
    }, [func]);
}

export {useResizeWindow}