import {useDocumentTitle} from "../../hooks/setDocumentTitle"
import {useState} from 'react'

import GamePlayer from '../../components/GamePlayer/GamePlayer'
import GameLibrary from '../../components/GameLibrary/GameLibrary'
import GameSelector from '../../components/GameSelector/GameSelector'

import Unselected from '../../games/Unselected'
import Snake from '../../games/Snake/Snake'
import TicTacToe from '../../games/TicTacToe/TicTacToe'

import './Games.css'
function Games() {
    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    useDocumentTitle("Games");
    const [name,setName] = useState("Unselected")
    const [mobile,setMobile] = useState(iOS())

    const GameList = [
        //['Testing',<Testing/>,true],
        ['Tic Tac Toe',<TicTacToe/>,false],
        ['Snake',<Snake/>,true],
    ]

    function getGame() {
        const element = GameList.filter(x => x[0] === name)
        return element.length === 1 ? element[0][1] : <Unselected/>
    }

    function isFullscreenable() {
        const element = GameList.filter(x => x[0] === name)
        return element.length === 1 ? element[0][2] : false
    }

    return (
        <div className='Games'>
            <div className='GamePlayerArea'>
                {mobile && 
                <span className='Warning'>
                    Click <span style={{ fontSize: '12px'}}>A</span>A → Hide Toolbar
                    <button onClick={() => {setMobile(false)}}>Dismiss</button>
                </span>}
                <GamePlayer name={name} fullscreenable={isFullscreenable()}>
                    {getGame()}
                </GamePlayer>
            </div>
            <div className='GameLibraryArea'>
                <GameLibrary>
                    {GameList.map((Game) => (
                        <GameSelector name={Game[0]} key={Game[0].toString()} select={setName}/>
                     ))}
                </GameLibrary>
            </div>
        </div>
    )
}

export default Games;