import {useDocumentTitle} from "../../hooks/setDocumentTitle"

function Connect() {
    useDocumentTitle("Connect");

    return (
        <div>
            Connect
        </div>
    )
}

export default Connect;