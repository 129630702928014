

class GameManager {   
    /**
    * Starts the draw cycle
    * @warning call this inside the useEffect, and don't forget to call finish!
    * @param {HTMLCanvasElement} canvas The canvas reference
    **/
    init(canvas) {
        this.finish()
        this.#canvas = canvas

        document.addEventListener('keydown', this.#handleKeyDown);
        document.addEventListener('keyup', this.#handleKeyUp);
        document.addEventListener('mousedown', this.#handleMouseDown);
        document.addEventListener('mouseup', this.#handleMouseUp);
        document.addEventListener('mousemove', this.#handleMouseMove);
        document.addEventListener('touchstart', this.#handleTouchStart);
        document.addEventListener('touchend', this.#handleTouchEnd);
        document.addEventListener('touchmove', this.#handleTouchMove);
        document.addEventListener('wheel', this.#handleScroll);

        this.#lastTick = (new Date()).getTime();
        this.#shouldTick = true
        this.#tick()
    }

    /**
    * Cancels the draw cycle
    * @warning call this inside the return function of the useEffect!
    **/
    finish() {
        this.#shouldTick = false

        document.removeEventListener('keydown', this.#handleKeyDown);
        document.removeEventListener('keyup', this.#handleKeyUp);
        document.removeEventListener('mousedown', this.#handleMouseDown);
        document.removeEventListener('mouseup', this.#handleMouseUp);
        document.removeEventListener('mousemove', this.#handleMouseMove);
        document.removeEventListener('touchstart', this.#handleTouchStart);
        document.removeEventListener('touchend', this.#handleTouchEnd);
        document.removeEventListener('touchmove', this.#handleTouchMove);
        document.removeEventListener('wheel', this.#handleScroll);

    }

    #keyDownEvents = {}
    #keyUpEvents = {}
    #mouseDownEvents = {}
    #mouseUpEvents = {}
    #mouseMoveEvents = {}
    #touchStartEvents = {}
    #touchEndEvents = {}
    #touchMoveEvents = {}
    #scrollEvents = {}

    get x() {return this.#x}
    get y() {return this.#y}
    get pressing() {return this.#pressing}
    static get EVENT_KEY_DOWN() { return 0 }
    static get EVENT_KEY_UP() { return 1 }
    static get EVENT_MOUSE_DOWN() { return 2 }
    static get EVENT_MOUSE_UP() { return 3 }
    static get EVENT_MOUSE_MOVE() { return 4 }
    static get EVENT_TOUCH_START() { return 5 }
    static get EVENT_TOUCH_END() { return 6 }
    static get EVENT_TOUCH_MOVE() { return 7 }
    static get EVENT_SCROLL() { return 8 }
    static get ISTOUCH() {
        return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0)
    }

    /**
    * @param type GameManager event type
    * @param {String} id the new event's id
    * @param {Function} func the event callback function
    */
    addEventListener(type,id,func) {
        switch (type) {
        case 0:
            this.#keyDownEvents[id] = func
            break;
        case 1:
            this.#keyUpEvents[id] = func
            break;
        case 2:
            this.#mouseDownEvents[id] = func
            break;
        case 3:
            this.#mouseUpEvents[id] = func
            break;
        case 4:
            this.#mouseMoveEvents[id] = func
            break;
        case 5:
            this.#touchStartEvents[id] = func
            break;
        case 6:
            this.#touchEndEvents[id] = func
            break;
        case 7:
            this.#touchMoveEvents[id] = func
            break;
        case 8:
            this.#scrollEvents[id] = func
            break;
        default:
            break;
        }
    }

    /**
    * @param type GameManager static types
    * @param {String} id the event id to remove
    */
    removeEventListener(type,id) {
        switch (type) {
        case 0:
            delete this.#keyDownEvents[id]
            break;
        case 1:
            delete this.#keyUpEvents[id]
            break;
        case 2:
            delete this.#mouseDownEvents[id]
            break;
        case 3:
            delete this.#mouseUpEvents[id]
            break;
        case 4:
            delete this.#mouseMoveEvents[id]
            break;
        case 5:
            delete this.#touchStartEvents[id]
            break;
        case 6:
            delete this.#touchEndEvents[id]
            break;
        case 7:
            delete this.#touchMoveEvents[id]
            break;
        case 8:
            delete this.#scrollEvents[id]
            break;
        default:
            break;
        }   
    }

    #handleKeyDown = (e) => {        
        this.#keysPressed[e.code] = {
            altKey: e.altKey,
            ctrlKey: e.ctrlKey,
            metaKey: e.metaKey,
            shiftKey: e.shiftKey
        }
        for (let func in this.#keyDownEvents) {
            this.#keyDownEvents[func](e)
        }
   }
    #handleKeyUp = (e) => {
        for (let func in this.#keyUpEvents) {
            this.#keyUpEvents[func](e)
        }
        delete this.#keysPressed[e.code]
    }
    /**
    * @param key The code of the key being pressed
    * @param {Object} options the additional options (alt, shift, meta, and ctrl)
    */
    isPressed(key,options) {
        if (!(key in this.#keysPressed)) return false
        
        const keyPressedOptions = this.#keysPressed[key]
        for (let property in options) {
            if (!(property in keyPressedOptions)) return false
            if (options.property !== keyPressedOptions.property) return false
        }
        return true        
    }

    #handleMouseDown = (e) => {
        this.#pressing = true
        this.#updateMousePosition(e)
        for (let func in this.#mouseDownEvents) {
            this.#mouseDownEvents[func](e)
        }
   }
    #handleMouseUp = (e) => {
        this.#pressing = false
        this.#updateMousePosition(e)
        for (let func in this.#mouseUpEvents) {
            this.#mouseUpEvents[func](e)
        }
    }
    #handleMouseMove = (e) => {
        this.#updateMousePosition(e)
        for (let func in this.#mouseMoveEvents) {
            this.#mouseMoveEvents[func](e)
        }  
    }
    #updateMousePosition(e) {
        this.#x = []
        this.#y = []
        const canvas = this.#canvas
        const rectangle = canvas.getBoundingClientRect();
        let x = Math.round(canvas.width * (e.clientX - rectangle.left) / rectangle.width)
        let y = Math.round(canvas.height * (e.clientY - rectangle.top) / rectangle.height)
        if (x <= canvas.width && x >= 0) {
            if (y <= canvas.height && y >= 0) {
                this.#x.push(x)
                this.#y.push(y)
            }
        }    
    }

    #handleTouchStart = (e) => {
        this.#pressing = true
        this.#updateTouchPosition(e)
        for (let func in this.#touchStartEvents) {
            this.#touchStartEvents[func](e)
        } 
    }
    #handleTouchEnd = (e) => {
        this.#pressing = false
        for (let func in this.#touchEndEvents) {
            this.#touchEndEvents[func](e)
        }
    }
    #handleTouchMove = (e) => {
        this.#updateTouchPosition(e)
        for (let func in this.#touchMoveEvents) {
            this.#touchMoveEvents[func](e)
        } 
    }
    #updateTouchPosition(e) {
        this.#x = []
        this.#y = []
        const canvas  = this.#canvas
        const rectangle = canvas.getBoundingClientRect();

        for (let touch of e.touches) {
            const x = Math.round(canvas.width * (touch.clientX - rectangle.left) / rectangle.width)
            const y = Math.round(canvas.height * (touch.clientY - rectangle.top) / rectangle.height)

            if (x <= canvas.width && x >= 0) {
                if (y <= canvas.height && y >= 0) {
                    this.#x.push(x)
                    this.#y.push(y)
                }
            }
        }
    }

    #handleScroll = (e) => {
        for (let func in this.#scrollEvents) {
            this.#scrollEvents[func](e)
        } 
    }

    /**
    * @param {String} name The scene name
    * @returns {Object} The state variable
    **/
    getState(name) {
        const scene = this.#scenes[name]
        return scene === null? null : scene[2]
    }

    /**
    * @returns {HTMLCanvasElement} The canvas reference
    **/
    getCanvas() {
        return this.#canvas
    }

    /**
    * @returns The selected scene name
    **/
    getSelectedScene() {
        return this.#selectedScene
    }
    
    /**
    * Add a new scene
    * @param {String} sceneName The name of the new scene
    * @param {Function} sceneDrawFunction a draw function () => {}
    * @param {Function} sceneUpdateFunction a update function (deltaTime) => {}
    * @param {Object} state (optional) the state given by the scene
    * @param {Function} sceneSelectFunction (optional) a function to run on select
    * @param {Function} sceneUnselectFunction (optional) a function to run when unselecting
    **/
    addScene(sceneName,sceneDrawFunction,sceneUpdateFunction,sceneState,sceneSelectFunction,sceneUnselectFunction) {
        const state = sceneState || {}
        const select = sceneSelectFunction || (() => {})
        const unselect = sceneUnselectFunction || (() => {})
        this.#scenes[sceneName] = [sceneDrawFunction,sceneUpdateFunction,state,select,unselect]
    }

    /**
    * Selects the scene to render
    * @warning call this inside a useEffect!
    * @param {String} sceneName the name of the new scene
    * @returns {String} name of the scene
    **/
    selectScene(sceneName,options) {
        const scene = this.#scenes[this.#selectedScene]
        if (scene) scene[4]()

        if (this.#scenes[sceneName]) {
            this.#drawScene = this.#scenes[sceneName][0]
            this.#updateScene = this.#scenes[sceneName][1]
            this.#scenes[sceneName][3](this.#selectedScene,options)
            this.#selectedScene = sceneName
        } else {
            this.#drawScene = () => {}
            this.#updateScene = () => {}
            this.#selectedScene = null
        }
        return this.#selectedScene
    }

    /**
    * Adds a function to the draw list
    * @param {String} id the draw function's id
    * @param {Func} func the draw function
    **/
    addToDrawList(id,func) {
        if (this.#drawList[id]) return
        this.#drawList[id] = func
    }

    /**
    * remove a function from the draw list
    * @param {String} id the draw function's id
    **/
    removeFromDrawList(id) {
        delete this.#drawList[id]
    }

    /** The default draw function, overidden by the scene selection */
    #drawScene = () => {}

    /** The default draw function, overidden by the scene selection */
    #updateScene = (deltaTime) => {}

    #tick = () => {
        if (this.#shouldTick) {
            const now = (new Date()).getTime(); // current time in ms
            const deltaTime = now - this.#lastTick; // amount of time elapsed since last tick
            this.#lastTick = now;

            this.#updateScene(deltaTime)

            const ctx = this.#canvas.getContext('2d')
            ctx.clearRect(0,0,this.#canvas.width,this.#canvas.height)
            this.#drawScene()

            for (let id in this.#drawList)
            {
                this.#drawList[id]()
            }

            window.requestAnimationFrame(this.#tick)
        }
    }

    /** @type {HTMLCanvasElement} The reference to the canvas being used for the game */
    #canvas = null

    /** The name of the selected scene */
    #selectedScene = null

    /** The collection of all drawable scenes */
    #scenes = {}

    /** The input x value */
    #x = []

    /** The input y value */
    #y = []

    /** The is there a current input */
    #pressing = false

    /** The list of keys currently being pressed*/
    #keysPressed = {}

    /** used by the draw and state update functions */
    #lastTick

    /** used by the draw and state update functions */
    #shouldTick

    /** Additional draw functions to call */
    #drawList = {}
}

export default GameManager