import GameManager from "../../util/GameManager"
import UIManager from "../../util/UIManager"
import isBadWord from "../../util/BadWordChecker"

export default function addMenuScene(m,i,u) {
    manager = m
    inputRef = i
    ui = u
    m.addScene('menu',draw,update,state,select,unselect)
}

/** @type {GameManager} */
let manager = null

let button = null

let inputRef = null

/** @type {UIManager} */
let ui = null

const draw = () => {
    const canvas = manager.getCanvas()
    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'white'
    ctx.textAlign = "center"
    ctx.font = 'bold 72px Poppins';
    ctx.fillText('TIC TAC TOE',canvas.width/2,250)
}

const update = () => {
    const username = inputRef.current.value.toLowerCase()

    if (isBadWord(username)) {
        state.username = ''
    } else {
        state.username = username
    }

    if (state.username === '') button.disable()
    else button.enable()

    inputRef.current.style.fontSize = `${manager.getCanvas().getBoundingClientRect().width/17}px`
}

const state = {}

const select = () => {
    inputRef.current.style.display = 'block'

    ui.addButton('single','SINGLEPLAYER',500,500,60,()=>{
        manager.selectScene('play')
    },{outlineWidth:6,padding:[12,182,182,0]})

    button = ui.addButton('multi','MULTIPLAYER',500,640,60,()=>{
        manager.selectScene('list')
    },{outlineWidth:6,padding:[12,196,196,0]})

    button.disable()
}

const unselect = () => {
    inputRef.current.style.display = 'none'

    ui.removeElement('single')
    ui.removeElement('multi')
}