import './GamePlayer.css'
import fullscreen from '../../assets/fullscreen.svg'
import { useState } from 'react';
function GamePlayer(prop) {
    const [full,setFull] = useState(false)
    function toggleFull() {
        setFull(!full)
    }
    
    return (
        <div className='GamePlayer'>
            <div className={full? 'FULL': ''}>
                <div className='PlayerArea'>
                    {prop.children}
                </div>
                {full &&
                <button className='Fullscreen' onClick={toggleFull}>
                    <img src={fullscreen} alt='Fullscreen'></img>
                </button>}
            </div>
            <div className='InfoArea'>
                <div className='TitleArea'>
                    <span className='Title'>
                        {prop.name}
                    </span>
                </div>
                {prop.fullscreenable &&
                <button className='Fullscreen' onClick={toggleFull}>
                    <img src={fullscreen} alt='Fullscreen'></img>
                </button>}
            </div>
        </div>   
    )
}

export default GamePlayer;
